<template>
  <div class="event-row d-md-flex flex-row justify-content-md-center align-items-md-center text-center w-100">
    <div class="row my-0 lab__info py-3 w-100">
      <div class="my-0 col-sm-2 d-flex flex-column justify-content-center align-items-start w-100"
        :class="{ 'simple-hover': isUser }">
        <h2 class="my-0 mt-xl-1 px-2" @click="redirectToLab">
          {{ labData.name }}
        </h2>
        <p v-if="!labData.noContainer && (this.isAdmin || isStartedAndParticipating)" class="my-0 px-2"
          @click="getLabIp(labData.id)" :class="{ 'starting-lab': isPendingAndParticipating && labStarting }">
          <i v-if="isUser" class="far fa-clipboard mr-2"></i>
          <template>
            COPY IP ADDRESS
          </template>
        </p>
        <!-- <p v-if="isPendingAndParticipating && isUser" class="mb-0">{{ getLabStatus() }}</p> -->
      </div>
      <div class="col-md-8 col-sm-10 row">
        <div
          class="col-md-1 d-none d-md-flex flex-row justify-content-center align-items-center w-100 lab__user-points">
          <div class="mx-2">
            {{ labData.userPoints }}
          </div>
          <div>
            <i class="fas fa-flag"></i>
          </div>
        </div>
        <div class="col-md-1 d-none d-md-flex flex-row justify-content-center align-items-center lab__root-points">
          <div class="mx-2">
            {{ labData.rootPoints }}
          </div>
          <div>
            <i class="fas fa-flag"></i>
          </div>
        </div>
        <div class="lab__difficulty w-100" :class="{ 'col-md-10': !redBlueOrNormal, 'col-md-6': redBlueOrNormal }">
          <span class="lab__difficulty--bar w-100" :style="`--value:${labProgressBar(labData)}%`">
          </span>
        </div>
        <div v-if="redOrNormal" class="col-md-4">
          <div v-if="isFirstblood">
            <p class="m-0 p-0" @click="redirectToFirstblood">{{ toUpper(firstbloodName) }}</p>
            <p v-if="isPwned" class="m-0 p-0 simple-hover">
              FIRSTBLOOD in days: {{ pwned_in.days }} - Minutes: {{ pwned_in.minutes }}
            </p>
          </div>
        </div>
        <div class="col-md-4 pr-0 d-flex flex-row align-items-center justify-content-start" v-if="shouldSeeLogs()">
          <h2>{{ labData.user.username }}</h2>
        </div>
      </div>
      <div class="col-md-2 w-100 d-flex flex-row justify-content-end align-content-center">
        <div v-if="shouldSeeEyeIcon()" @click="showDiffs(labData.id, labData.user.id)"
          class="w-100 d-flex flex-row justify-content-end align-items-center">
          <span class="lab__run-check__orange d-flex flex-row justify-content-center align-items-center">
            <i class="fa fa-eye"></i>
          </span>
        </div>
        <div v-if="shouldSeeMagnifyingIcon()" @click="showLogs(labData.id, labData.user_id)"
          class="w-100 d-flex flex-row justify-content-end align-items-center">
          <span class="lab__run-check__orange d-flex flex-row justify-content-center align-items-center">
            <i class="fa fa-history"></i>
          </span>
        </div>

        <div v-if="labNote" class="w-100 d-flex flex-row justify-content-end align-items-center"
          @click="openLabNotes()">
          <span class="lab__run-check__orange d-flex flex-row justify-content-center align-items-center">
            <i class="fa-solid fa-tower-broadcast"></i>
          </span>
        </div>

        <div v-if="labData.downloadable" class="w-100 d-flex flex-row justify-content-end align-items-center"
          @click="downloadLabTemplate">
          <span class="lab__run-check__orange d-flex flex-row justify-content-center align-items-center">
            <i class="fa-solid fa-download"></i>
          </span>
        </div>


        <div v-if="this.isBlueEvent && isStartedAndParticipating" @click="downloadSSHkeys"
          class="w-100 d-flex flex-row justify-content-end align-items-center">
          <span class="lab__run-check__orange d-flex flex-row justify-content-center align-items-center">
            <i class="fas fa-download"></i>
          </span>
        </div>
        <div v-if="this.isBlueEvent && isStartedAndParticipating" @click="$emit('runDoberman', labData.id)"
          class="w-100 d-flex justify-content-center align-items-center h-100">
          <span class="lab__run-check__orange d-flex flex-row justify-content-center align-items-center">
            <img :src="this.$parent.$data.dobermannIcon" alt="dobermann" />
          </span>
        </div>
        <div v-if="isStartedAndParticipating && isUser"
          class="w-100 d-flex flex-row justify-content-end align-items-center"
          @click="askReset(labData.id, labData.noContainer)">
          <span class="d-flex flex-row justify-content-center align-items-center">
            <i class="lab-reset fas fa-undo" :class="{ 'disabled': !userHasStartId }"
              :style="getResetBtnStyles(labData)"></i>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import EventBannerTextModal from "@/components/EventBannerTextModal";
import UserType from "@/enums/user.js";
import labService from "../api/lab";
import { AwsLabStatus } from "../enums/lab";
import DockerLogsModal from "./DockerLogsModal";
import FileDiffModal from "./FileDiffModal";

export default {
  name: "EventRow",
  data() {
    return {
      labStarting: false,
      isPending: true,
      isPendingInterval: null,
      flagString: null,
      pwned_in: false,
      userRole: null,
      isEventPrivate: false,
      isAdmin: false,
      isGreenEvent: this.$parent.$data.isGreenEvent,
      isRedEvent: this.$parent.$data.isRedEvent,
      isNormalEvent: this.$parent.$data.isNormalEvent,
      isBlueEvent: this.$parent.$data.isBlueEvent,
      isOnGoing: this.$parent.$data.isOnGoing,
      isStarted: this.$parent.$data.isStarted,
      isParticipating: this.$parent.$data.isParticipating,
      isUser: false,
    };
  },
  props: {
    labNote: null,
    labId: null,
    labData: {
      id: null,
      status: null,
      firstblood: {
        user_id: null,
        username: null,
        team_name: null,
        lab_id: null,
        pwned_in: {
          days: null,
          minutes: null,
        },
      },
    },
    eventData: {},
  },
  created() {
    this.isUser = this.$parent.$data.user.data.user.role == UserType.USER
    // const note = this.labNote;

    this.isEventPrivate = this.$parent.$data.event.private;
    if (
      this.labData.firstblood !== undefined &&
      this.labData.firstblood.pwned_in !== undefined
    ) {
      this.pwned_in = this.labData.firstblood.pwned_in;
    }
    this.userRole = this.$parent.user.data.user.role;
    this.isAdmin = this.userRole === 1;
    if (!this.labData.isAws) this.isPending = false;
  },
  beforeDestroy() {
    clearInterval(this.isPendingInterval);
  },
  watch: {
    'labData.ip': function (new_) {
      if (new_ !== "N/A") this.labStarting = false;
    }
  },
  computed: {
    userHasStartId() {
      return this.eventData.startId.started
    },
    isPwned() {
      const isThisLab = this.labData.id == this.labId
      return this.labData.firstblood.pwned_in && isThisLab
    },
    isRecruiterAllowed() {
      const isHrpRecruiter = this.$parent.$data.event.hrpRecruiter;
      return isHrpRecruiter;
    },
    redBlueOrNormal() {
      return this.isRedEvent || this.isBlueEvent || this.isNormalEvent
    },
    redOrNormal() {
      return this.isRedEvent || this.isNormalEvent
    },
    isPendingAndParticipating() {
      const isPending = this.isPending;
      const isParticipating = this.isParticipating;
      return isPending && isParticipating
    },
    isStartedAndParticipating() {
      const isParticipating = this.isParticipating;
      const isStarted = this.isStarted;
      return isStarted && isParticipating;
    },
    isFirstblood() {
      if (this.isRedEvent) return this.labData.firstblood.team_name !== null
      return this.labData.firstblood.username !== null
    },
    firstbloodName() {
      if (this.isRedEvent) return this.labData.firstblood.team_name
      return this.labData.firstblood.username
    },
  },
  methods: {
    getResetBtnStyles(lab) {
      const noContainer = lab.noContainer;
      const styles = {
        pointerEvents: noContainer ? 'none' : '',
      }

      if (noContainer) styles["color"] = "gray";
      return styles;
    },
    async downloadLabTemplate() {
      this.$parent.loading = true;
      const labId = this.labData.id;
      const labName = this.labData.name.replace(" ", "-");
      const labBytes = await labService.downloadLabTemplate(labId)
        .then((response) => response.data);

      const buffer = await labBytes.arrayBuffer();

      const blob = new Blob([buffer], { type: "application/zip" })
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement("a");
      link.href = url;
      link.download = `${labName}.zip`;
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
      this.$parent.loading = false;
    },
    labProgressBar(lab) {
      return String(lab.difficulty);
    },
    openLabNotes() {
      const notes = this.labNote;
      this.$modal.show(
        EventBannerTextModal,
        {
          bannerText: notes.text,
          title: notes.title,
          closeCallback: () => { }
        },
        { height: "auto" }
      )
    },
    askReset(labId, noContainer = false) {
      if (noContainer) return;
      this.$emit('askReset', labId)
    },
    toUpper(string) {
      if (!string) return string
      if (typeof string !== "string") return string
      return string.toUpperCase();
    },
    async getLabIp(labId) {
      this.$emit("getLabIp", labId);
    },
    redirectToFirstblood() {
      if (this.isRedEvent) return this.$router.push(["/team", this.labData.firstblood.team_id].join("/"))
      return this.$router.push(["/user", this.labData.firstblood.user_id].join("/"))
    },
    copyToClipboard(ip) {
      if (!this.isOnGoing || !this.isParticipating) return;
      this.getLabStatus();
      if (this.isPending) {
        this.flashError("Cannot copy, lab is still pending");
        return;
      }
      navigator.clipboard.writeText(ip);
      this.flashSuccess(`${ip} copied to clipboard`);
    },
    shouldSeeClipboardBtn() {
      if (!this.isOnGoing) return false;
      if (!this.isParticipating) return false;
      return true;
    },
    rowResponsiveLayout() {
      const isParticipating = this.$parent.$data.isParticipating;
      const isPurpleEvent = this.$parent.$data.isPurpleEvent;
      const isRecruiter = this.userRole === 3;
      const isAdmin = this.isAdmin;
      const isBlueEvent = this.$parent.$data.isBlueEvent;
      const isGreenEvent = this.$parent.$data.isGreenEvent;

      if (isParticipating) return "col-xl-12";
      if (isPurpleEvent) return "col-xl-12";
      if (isRecruiter) return "col-xl-12";
      if (isGreenEvent) return "col-xl-12";
      if (isAdmin && isBlueEvent) return "col-xl-12";

      return "py-2 px-2 my-2";
    },
    getLabStatus() {
      if (this.labData.status === AwsLabStatus.PENDING) {
        this.isPending = true;
        this.isPendingInterval = setInterval(this.getLabStatus, 5000);
        return;
      }
      if (
        this.labData.status === AwsLabStatus.COMPLETED ||
        this.labData.status === undefined
      ) {
        this.isPending = false;
        return;
      }
      return this.labData.status;
    },
    redirectToLab() {
      if (!this.isEventPrivate) {
        const routeData = this.$parent.$router.resolve({
          path: `/lab/${this.labData.id}`,
        })
        // this.$parent.$router.push(`/lab/${this.labData.id}`);
        window.open(routeData.href, "_blank");
      }
    },
    shouldSeeEyeIcon() {
      const eventData = this.$parent.$data.event;
      if (!this.$parent.$data.isOverdue) return false;
      if (!this.$parent.$data.isBlueEvent) return false;
      if (this.userRole !== 3) return false;
      if (!eventData.owner && this.$parent.user.data.user.role !== 1)
        return false;
      return true;
    },
    shouldSeeLogs() {
      if (!this.$parent.$data.isBlueEvent) return false;
      if (this.userRole !== 3 && !this.isAdmin) return false;
      return true;
    },
    shouldSeeMagnifyingIcon() {
      const eventData = this.$parent.$data.event;
      // if (!this.$parent.$data.isOnGoing) return false;
      if (this.$parent.isGreenEvent) return false;
      if (this.userRole !== 3 && !this.isAdmin && !eventData.owner)
        return false;
      return true;
    },
    showDiffs(labId, userId) {
      this.$modal.show(
        FileDiffModal,
        {
          eventId: this.$parent.$data.event.id,
          labId: labId,
          userId: userId,
        },
        { height: "auto", width: "80%" }
      );
    },
    showLogs(labId, userId) {
      const isHrpRecruiter = this.$parent.$data.event.hrpRecruiter;
      const userIdForHrp = this.$parent.$data.userId;
      this.$modal.show(
        DockerLogsModal,
        {
          eventId: this.$parent.$data.event.id,
          labId: labId,
          userId: isHrpRecruiter ? userIdForHrp : userId,
        },
        { height: "auto", width: "80%" }
      );
    },
    downloadSSHkeys() {
      this.$emit("downloadKey", this.labData);
    },
    isRecruiter() {
      return this.userRole !== undefined && this.userRole === 3;
    },
    submitFlag(eventId, labId) {
      this.flagString = document.getElementById(labId).value;
      if (!this.flagString) {
        this.flashError("Flag cannot be empty");
        return;
      }
      this.$parent.loading = true;
      labService
        .submitFlag(eventId, labId, this.flagString)
        .then((response) => {
          const successMessage =
            !!response && !!response.data && !!response.data.message;
          if (successMessage) {
            this.flashSuccess(response.data.message);
          }
          this.onClose(true);
        })
        .catch((error) => {
          const manageableError =
            !!error && !!error.data && !!error.data.errorDescription;
          if (manageableError) {
            this.flashError(error.data.errorDescription);
          }
          this.onClose(false);
        })
        .finally(() => {
          this.$parent.loading = false;
          this.emit("submittedFlag");
        });
    },
    refreshData() {
      this.$parent.handleRanks();
      this.$parent.handleLabs();
    },
  },
};
</script>

<style lang="scss">
@import "../assets/css/colors.scss";

.event-row {
  // height: 50px;

  &:nth-child(even) {
    .lab__info {
      background: $neutral--medium;
    }
  }

  &:nth-child(odd) {
    .lab__info {
      background: $neutral--smooth;
    }
  }

  h2 {
    font-size: 0.8rem;
    opacity: 0.8;
  }

  p {
    font-size: 0.7rem;
  }
}

.lab__difficulty {
  align-items: center;
  justify-content: center;
  display: flex;
  height: 100%;

  >span {
    background: rgba($color: $neutral--dark, $alpha: 0.55);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    width: 100%;
    height: 10px;
    position: relative;
    font-size: 0;

    &:after {
      clip-path: inset(0px calc(100% - var(--value)) 0px 0px round 15px);
      border-radius: 10px 10px 10px 10px;
      -webkit-border-radius: 10px 10px 10px 10px;
      -moz-border-radius: 10px 10px 10px 10px;
      content: "";
      width: 100%;
      left: 0;
      position: absolute;
      height: 10px;
      background: #e5405e;
      background: linear-gradient(to right,
          #3fffa2 0%,
          #ffdb3a 35%,
          #ff993a 60%,
          #e5405e 100%);
    }
  }
}

.lab__difficulty--bar {
  display: block;
  background: rgba($color: $neutral--dark, $alpha: 0.75);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  width: 100%;
  height: 10px;
  position: relative;
  font-size: 0;

  &:after {
    content: "";
    clip-path: inset(0px calc(100% - var(--value)) 0px 0px round 15px);
    border-radius: 10px 10px 10px 10px;
    -webkit-border-radius: 10px 10px 10px 10px;
    -moz-border-radius: 10px 10px 10px 10px;
    width: 100%;
    left: 0;
    position: absolute;
    height: 10px;
    background: #e5405e;
    background: linear-gradient(to right,
        #3fffa2 0%,
        #ffdb3a 35%,
        #ff993a 60%,
        #e5405e 100%);
  }
}

::-webkit-scrollbar {
  width: 0;
  /* Remove scrollbar space */
  background: transparent;
  /* Optional: just make scrollbar invisible */
}

.lab__root-points {
  i {
    color: $soft-orange;
  }

  color: $soft-orange;
}

.lab__user-points {
  i {
    color: $soft-green;
  }

  color: $soft-green;
}

.lab-reset {
  transition: 0.5s ease-in-out;
  position: relative;
  z-index: 2;
  cursor: pointer;

  &:after {
    content: "";
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
    height: 100%;
    width: 100%;
    background: rgba(white, 0.1);
    border-radius: 50%;
    z-index: -1;
    transition: 0.2s all;
  }

  &:hover {
    transform: rotateZ(-360deg);

    &:after {
      height: 220%;
      width: 220%;
    }
  }
}

.lab__run-check__orange {
  cursor: pointer;
  color: $neutral--light;
  border-radius: 50%;
  position: relative;
  z-index: 2;

  &:after {
    content: "";
    position: absolute;
    border-radius: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    z-index: -1;
    transition: 0.2s all;
  }

  &:hover {
    &:after {
      background: rgba($soft-orange, 0.5);
      width: 220%;
      height: 220%;
    }
  }
}

.starting-lab {
  color: $soft-green !important;
  text-transform: uppercase;
  animation: blink 0.5s ease-in infinite alternate;
}

@keyframes blink {
  100% {
    opacity: 0;
  }
}
</style>
